import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout/Layout';
import PageTemplateDetails from '../components/PageTemplateDetails/PageTemplateDetails';

const PageTemplate = (props) => {
  const { title, subtitle } = props.data.site.siteMetadata;
  const page = props.data.markdownRemark;
  const { title: pageTitle, description: pageDescription } = page.frontmatter;
  const description = pageDescription !== null ? pageDescription : subtitle;
  const { location } = props;

  return (
    <Layout location={location} title={description}>
      <div>
        <Helmet>
          <script defer data-domain="tderflinger.com" src="https://plausible.io/js/script.js"></script>
          <title>{`${pageTitle} - ${title}`}</title>
          <meta name="description" content={description} />
        </Helmet>
        <PageTemplateDetails {...props} />
      </div>
    </Layout>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        url
        author {
          name
          nameFr
          twitter
          github
          rss
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        lang
        path
      }
    }
  }
`;
