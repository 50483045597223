import React from 'react';
import ContactForm from '../ContactForm/ContactForm';
import Sidebar from '../Sidebar/Sidebar';

const PageTitle = ({ title }) => {
  return (
    <h1 className="mt-2" id="anc">
      {title}
    </h1>
  );
};

const PageTemplateDetails = (props) => {
  const page = props.data.markdownRemark;

  return (
    <>
      <Sidebar {...props} lang={page.frontmatter.lang} />
      <div className="content">
        <div className="my-10 mx-6">
          <PageTitle title={page?.frontmatter?.title} />
          {page.frontmatter.path === '/en/contact' ? (
            <ContactForm lang="en" />
          ) : null}
          {page.frontmatter.path === '/fr/contact' ? (
            <ContactForm lang="fr" />
          ) : null}
          {page.frontmatter.path === '/es/contacto' ? (
            <ContactForm lang="es" />
          ) : null}

          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: page.html }}
          />
        </div>
      </div>
    </>
  );
};

export default PageTemplateDetails;
