import TextField from '@mui/material/TextField';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { sendMessage } from '../../api/EmailApi';
import { translateText } from '../../common/LanguageTexts';
import FormSubmitButton from '../Buttons/FormSubmitButton/FormSubmitButton';
import ContactFormIntroText from './ContactFormIntroText';
import { validateEmail } from './Validation';

const ContactForm = ({ lang }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sending, setSending] = useState(false);

  const clickButton = async (evt) => {
    evt.preventDefault();

    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailHelperText(translateText(lang, 'EmailHelperText'));
      return;
    }

    if (validateEmail(email) && message !== '') {
      const data = {
        name,
        email,
        message,
      };
      try {
        setSending(true);
        await sendMessage(data);
        setEmail('');
        setName('');
        setMessage('');
        setSuccessMessage(translateText(lang, 'EmailSuccessMessage'));
        setSending(false);
      } catch (err) {
        setErrorMessage(translateText(lang, 'EmailErrorMessage'));
        setSending(false);
      }
    }
  };

  const emailBlur = (evt) => {
    evt.preventDefault();

    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailHelperText(translateText(lang, 'ValidEmailText'));
    } else {
      setEmailError(false);
      setEmailHelperText(null);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <form>
      <StaticImage src="../../assets/images/web.jpg" alt="Contact Me" />
      <ContactFormIntroText lang={lang} />
      <h2 className="mt-0 text-blue-800">{successMessage}</h2>
      <h2 className="mt-0 text-red-800">{errorMessage}</h2>
      <TextField
        margin="normal"
        id="name"
        onChange={handleNameChange}
        value={name}
        label={translateText(lang, 'NameLabel')}
        variant="outlined"
      />
      <br />
      <TextField
        margin="normal"
        id="email"
        error={emailError}
        onChange={handleEmailChange}
        value={email}
        label={translateText(lang, 'EmailLabel')}
        variant="outlined"
        onBlur={emailBlur}
        helperText={emailHelperText}
        required
      />
      <br />
      <TextField
        className="w-full"
        rows={5}
        multiline
        margin="normal"
        id="message"
        onChange={handleMessageChange}
        value={message}
        label={translateText(lang, 'MessageLabel')}
        variant="outlined"
        required
      />
      <FormSubmitButton onClickButton={clickButton} lang={lang} loading={sending} />
    </form>
  );
}

export default ContactForm;
