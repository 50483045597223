import Button from '@mui/material/Button';
import React from 'react';
import { translateText } from '../../../common/LanguageTexts';
import CircularProgress from '@mui/material/CircularProgress';

const FormSubmitButton = ({ lang, onClickButton, loading }) => {
  return (
    <div className="mt-4 mb-6 flex">
      <Button variant="contained" onClick={onClickButton}>
        {translateText(lang, 'FormSubmitButton')}
      </Button>
      {loading && <CircularProgress className="ml-4"/>}
    </div>
  );
}

export default FormSubmitButton;
